/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M7.41485 5.64497L5.29485 3.52497C5.24837 3.47811 5.19307 3.44091 5.13214 3.41553C5.07121 3.39014 5.00586 3.37708 4.93985 3.37708C4.87384 3.37708 4.80849 3.39014 4.74756 3.41553C4.68663 3.44091 4.63133 3.47811 4.58485 3.52497C4.49172 3.61866 4.43945 3.74538 4.43945 3.87747C4.43945 4.00957 4.49172 4.13629 4.58485 4.22997L6.35485 5.99997L4.58485 7.76997C4.49172 7.86366 4.43945 7.99038 4.43945 8.12247C4.43945 8.25457 4.49172 8.38129 4.58485 8.47497C4.63157 8.52132 4.68698 8.55798 4.7479 8.58286C4.80881 8.60774 4.87405 8.62035 4.93985 8.61997C5.00565 8.62035 5.07088 8.60774 5.1318 8.58286C5.19272 8.55798 5.24813 8.52132 5.29485 8.47497L7.41485 6.35497C7.46171 6.30849 7.49891 6.25319 7.52429 6.19226C7.54968 6.13133 7.56275 6.06598 7.56275 5.99997C7.56275 5.93397 7.54968 5.86862 7.52429 5.80769C7.49891 5.74676 7.46171 5.69146 7.41485 5.64497Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'AngleRight';

export const AngleRight12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
