/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M8.49985 6.70503L6.35485 4.58503C6.30837 4.53817 6.25307 4.50097 6.19214 4.47559C6.13121 4.4502 6.06586 4.43713 5.99985 4.43713C5.93385 4.43713 5.86849 4.4502 5.80756 4.47559C5.74663 4.50097 5.69133 4.53817 5.64485 4.58503L3.52485 6.70503C3.47799 6.75151 3.44079 6.80681 3.41541 6.86774C3.39002 6.92867 3.37695 6.99403 3.37695 7.06003C3.37695 7.12604 3.39002 7.19139 3.41541 7.25232C3.44079 7.31325 3.47799 7.36855 3.52485 7.41503C3.61853 7.50816 3.74526 7.56043 3.87735 7.56043C4.00945 7.56043 4.13617 7.50816 4.22985 7.41503L5.99985 5.64503L7.76985 7.41503C7.86298 7.50741 7.98868 7.55948 8.11985 7.56003C8.18566 7.56041 8.25089 7.5478 8.31181 7.52292C8.37273 7.49804 8.42813 7.46137 8.47485 7.41503C8.52339 7.37022 8.56257 7.31624 8.59012 7.2562C8.61767 7.19617 8.63306 7.13126 8.63538 7.06525C8.63771 6.99923 8.62693 6.9334 8.60366 6.87158C8.5804 6.80975 8.54512 6.75314 8.49985 6.70503Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'AngleUp';

export const AngleUp12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
