/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M11.39 5.18501C11.3429 5.127 11.2835 5.08027 11.216 5.04826C11.1485 5.01624 11.0747 4.99976 11 5.00001H10V4.50001C10 4.10219 9.84196 3.72066 9.56066 3.43935C9.27936 3.15805 8.89782 3.00001 8.5 3.00001H5.36L5.2 2.50001C5.09628 2.20663 4.90387 1.95276 4.64945 1.77359C4.39503 1.59442 4.09118 1.49881 3.78 1.50001H2C1.60218 1.50001 1.22064 1.65805 0.93934 1.93935C0.658035 2.22066 0.5 2.60219 0.5 3.00001V9.00001C0.5 9.39784 0.658035 9.77937 0.93934 10.0607C1.22064 10.342 1.60218 10.5 2 10.5H9.2C9.54061 10.499 9.87074 10.3821 10.1361 10.1686C10.4014 9.95503 10.5862 9.65753 10.66 9.32501L11.5 5.61001C11.5152 5.53547 11.5132 5.45844 11.4941 5.38479C11.4751 5.31114 11.4395 5.24282 11.39 5.18501ZM2.685 9.11001C2.6596 9.2228 2.5959 9.3233 2.50473 9.39441C2.41357 9.46552 2.30058 9.50284 2.185 9.50001H2C1.86739 9.50001 1.74021 9.44733 1.64645 9.35356C1.55268 9.2598 1.5 9.13262 1.5 9.00001V3.00001C1.5 2.8674 1.55268 2.74023 1.64645 2.64646C1.74021 2.55269 1.86739 2.50001 2 2.50001H3.78C3.88903 2.49432 3.99693 2.52447 4.08722 2.58587C4.1775 2.64726 4.24521 2.73652 4.28 2.84001L4.55 3.66001C4.58209 3.75538 4.6422 3.83886 4.72249 3.89952C4.80277 3.96018 4.89949 3.9952 5 4.00001H8.5C8.63261 4.00001 8.75979 4.05269 8.85355 4.14646C8.94732 4.24023 9 4.3674 9 4.50001V5.00001H4C3.88442 4.99719 3.77143 5.0345 3.68027 5.10561C3.5891 5.17672 3.5254 5.27722 3.5 5.39001L2.685 9.11001ZM9.685 9.11001C9.6596 9.2228 9.59589 9.3233 9.50473 9.39441C9.41357 9.46552 9.30058 9.50284 9.185 9.50001H3.605C3.63082 9.4443 3.64931 9.38548 3.66 9.32501L4.4 6.00001H10.4L9.685 9.11001Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'FolderOpen';

export const FolderOpen12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
