/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M10.1927 10.86L8.66602 12.3934V4.66671C8.66602 4.4899 8.59578 4.32033 8.47075 4.1953C8.34573 4.07028 8.17616 4.00004 7.99935 4.00004C7.82254 4.00004 7.65297 4.07028 7.52794 4.1953C7.40292 4.32033 7.33268 4.4899 7.33268 4.66671V12.3934L5.80602 10.86C5.68048 10.7345 5.51022 10.664 5.33268 10.664C5.15515 10.664 4.98488 10.7345 4.85935 10.86C4.73381 10.9856 4.66329 11.1558 4.66329 11.3334C4.66329 11.5109 4.73381 11.6812 4.85935 11.8067L7.52602 14.4734C7.58942 14.5341 7.66418 14.5816 7.74602 14.6134C7.82582 14.6486 7.9121 14.6669 7.99935 14.6669C8.0866 14.6669 8.17288 14.6486 8.25268 14.6134C8.33452 14.5816 8.40928 14.5341 8.47268 14.4734L11.1393 11.8067C11.2649 11.6812 11.3354 11.5109 11.3354 11.3334C11.3354 11.1558 11.2649 10.9856 11.1393 10.86C11.0772 10.7979 11.0034 10.7486 10.9222 10.7149C10.841 10.6813 10.7539 10.664 10.666 10.664C10.4885 10.664 10.3182 10.7345 10.1927 10.86ZM12.666 1.33337H3.33268C3.15587 1.33337 2.9863 1.40361 2.86128 1.52864C2.73625 1.65366 2.66602 1.82323 2.66602 2.00004C2.66602 2.17685 2.73625 2.34642 2.86128 2.47145C2.9863 2.59647 3.15587 2.66671 3.33268 2.66671H12.666C12.8428 2.66671 13.0124 2.59647 13.1374 2.47145C13.2624 2.34642 13.3327 2.17685 13.3327 2.00004C13.3327 1.82323 13.2624 1.65366 13.1374 1.52864C13.0124 1.40361 12.8428 1.33337 12.666 1.33337Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'ArrowFromTop';

export const ArrowFromTop16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
