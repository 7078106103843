/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66602 12C4.66602 11.6319 4.96449 11.3334 5.33268 11.3334H13.9993C14.3675 11.3334 14.666 11.6319 14.666 12C14.666 12.3682 14.3675 12.6667 13.9993 12.6667H5.33268C4.96449 12.6667 4.66602 12.3682 4.66602 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33398 12C1.33398 11.6319 1.63246 11.3334 2.00065 11.3334H2.00732C2.37551 11.3334 2.67398 11.6319 2.67398 12C2.67398 12.3682 2.37551 12.6667 2.00732 12.6667H2.00065C1.63246 12.6667 1.33398 12.3682 1.33398 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66602 8.00004C4.66602 7.63185 4.96449 7.33337 5.33268 7.33337H13.9993C14.3675 7.33337 14.666 7.63185 14.666 8.00004C14.666 8.36823 14.3675 8.66671 13.9993 8.66671H5.33268C4.96449 8.66671 4.66602 8.36823 4.66602 8.00004Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33398 8.00004C1.33398 7.63185 1.63246 7.33337 2.00065 7.33337H2.00732C2.37551 7.33337 2.67398 7.63185 2.67398 8.00004C2.67398 8.36823 2.37551 8.66671 2.00732 8.66671H2.00065C1.63246 8.66671 1.33398 8.36823 1.33398 8.00004Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66602 4.00004C4.66602 3.63185 4.96449 3.33337 5.33268 3.33337H13.9993C14.3675 3.33337 14.666 3.63185 14.666 4.00004C14.666 4.36823 14.3675 4.66671 13.9993 4.66671H5.33268C4.96449 4.66671 4.66602 4.36823 4.66602 4.00004Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33398 4.00004C1.33398 3.63185 1.63246 3.33337 2.00065 3.33337H2.00732C2.37551 3.33337 2.67398 3.63185 2.67398 4.00004C2.67398 4.36823 2.37551 4.66671 2.00732 4.66671H2.00065C1.63246 4.66671 1.33398 4.36823 1.33398 4.00004Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'List';

export const List16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
