/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M22.78 10.37C22.6859 10.254 22.567 10.1605 22.432 10.0965C22.297 10.0325 22.1494 9.99951 22 10H20V9.00002C20 8.20437 19.6839 7.44131 19.1213 6.8787C18.5587 6.31609 17.7956 6.00002 17 6.00002H10.72L10.4 5.00002C10.1926 4.41325 9.80775 3.90553 9.2989 3.54718C8.79005 3.18884 8.18236 2.99762 7.56 3.00002H4C3.20435 3.00002 2.44129 3.31609 1.87868 3.8787C1.31607 4.44131 1 5.20437 1 6.00002V18C1 18.7957 1.31607 19.5587 1.87868 20.1213C2.44129 20.684 3.20435 21 4 21H18.4C19.0812 20.998 19.7415 20.7643 20.2722 20.3372C20.8029 19.9101 21.1724 19.3151 21.32 18.65L23 11.22C23.0304 11.0709 23.0264 10.9169 22.9883 10.7696C22.9502 10.6223 22.8789 10.4856 22.78 10.37ZM5.37 18.22C5.3192 18.4456 5.19179 18.6466 5.00946 18.7888C4.82714 18.931 4.60116 19.0057 4.37 19H4C3.73478 19 3.48043 18.8947 3.29289 18.7071C3.10536 18.5196 3 18.2652 3 18V6.00002C3 5.73481 3.10536 5.48045 3.29289 5.29292C3.48043 5.10538 3.73478 5.00002 4 5.00002H7.56C7.77807 4.98864 7.99386 5.04895 8.17443 5.17174C8.355 5.29452 8.49042 5.47304 8.56 5.68002L9.1 7.32002C9.16417 7.51077 9.2844 7.67771 9.44497 7.79903C9.60554 7.92035 9.79898 7.9904 10 8.00002H17C17.2652 8.00002 17.5196 8.10538 17.7071 8.29292C17.8946 8.48045 18 8.73481 18 9.00002V10H8C7.76884 9.99437 7.54286 10.069 7.36054 10.2112C7.17821 10.3534 7.0508 10.5544 7 10.78L5.37 18.22ZM19.37 18.22C19.3192 18.4456 19.1918 18.6466 19.0095 18.7888C18.8271 18.931 18.6012 19.0057 18.37 19H7.21C7.26165 18.8886 7.29862 18.771 7.32 18.65L8.8 12H20.8L19.37 18.22Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'FolderOpen';

export const FolderOpen24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
