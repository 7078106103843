/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_768_9941)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5ZM0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.5C6.27614 5.5 6.5 5.72386 6.5 6V8C6.5 8.27614 6.27614 8.5 6 8.5C5.72386 8.5 5.5 8.27614 5.5 8V6C5.5 5.72386 5.72386 5.5 6 5.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 4C5.5 3.72386 5.72386 3.5 6 3.5H6.005C6.28114 3.5 6.505 3.72386 6.505 4C6.505 4.27614 6.28114 4.5 6.005 4.5H6C5.72386 4.5 5.5 4.27614 5.5 4Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_768_9941">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'Info';

export const Info12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
