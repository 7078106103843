/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_227_2437)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 16.2929C7.68342 15.9024 8.31658 15.9024 8.70711 16.2929L12 19.5858L15.2929 16.2929C15.6834 15.9024 16.3166 15.9024 16.7071 16.2929C17.0976 16.6834 17.0976 17.3166 16.7071 17.7071L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L7.29289 17.7071C6.90237 17.3166 6.90237 16.6834 7.29289 16.2929Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11C12.5523 11 13 11.4477 13 12V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V12C11 11.4477 11.4477 11 12 11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66679 1.99416C10.0273 1.94523 11.3811 2.20558 12.6264 2.7556C13.8717 3.30562 14.976 4.13098 15.8562 5.16954C16.5667 6.0079 17.1162 6.96755 17.4796 7.99999L17.9984 7.99999C19.2735 7.99916 20.5166 8.40459 21.5457 9.15747C22.5751 9.9105 23.3377 10.972 23.7229 12.1878C24.1081 13.4036 24.0959 14.7106 23.688 15.919C23.2801 17.1274 22.4977 18.1744 21.4544 18.908C21.0026 19.2257 20.3789 19.117 20.0612 18.6652C19.7435 18.2134 19.8522 17.5897 20.304 17.272C20.9995 16.7829 21.5211 16.0849 21.793 15.2793C22.0649 14.4737 22.0731 13.6024 21.8163 12.7919C21.5595 11.9813 21.0511 11.2737 20.3648 10.7716C19.6786 10.2696 18.8502 9.99933 18 9.99999H16.7392C16.2826 9.99999 15.8841 9.69077 15.7706 9.24854C15.5074 8.22293 15.015 7.27038 14.3304 6.46261C13.6459 5.65484 12.787 5.01289 11.8184 4.5851C10.8498 4.1573 9.79682 3.95481 8.73867 3.99286C7.68051 4.03092 6.64478 4.30852 5.70944 4.80479C4.7741 5.30105 3.96353 6.00303 3.33874 6.85789C2.71396 7.71275 2.29125 8.6982 2.10243 9.74007C1.91362 10.7819 1.96361 11.8531 2.24866 12.8728C2.53371 13.8926 3.04638 14.8343 3.74808 15.6273C4.11408 16.0409 4.0755 16.6729 3.66191 17.0389C3.24831 17.4049 2.61632 17.3663 2.25032 16.9527C1.34814 15.9332 0.688989 14.7223 0.322499 13.4112C-0.0439923 12.1001 -0.108275 10.723 0.13449 9.38342C0.377255 8.04388 0.92074 6.77686 1.72403 5.67776C2.52732 4.57865 3.56949 3.67611 4.77207 3.03806C5.97465 2.40001 7.30631 2.04308 8.66679 1.99416Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_227_2437">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'DownloadCloud';

export const DownloadCloud24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
