/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M16.6673 7.44996C16.6586 7.3734 16.6419 7.29799 16.6173 7.22496V7.14996C16.5773 7.06428 16.5238 6.98551 16.459 6.91663L11.459 1.91663C11.3901 1.85181 11.3113 1.79836 11.2257 1.75829H11.1507C11.066 1.70974 10.9725 1.67858 10.8757 1.66663H5.83398C5.17094 1.66663 4.53506 1.93002 4.06622 2.39886C3.59738 2.8677 3.33398 3.50358 3.33398 4.16663V15.8333C3.33398 16.4963 3.59738 17.1322 4.06622 17.6011C4.53506 18.0699 5.17094 18.3333 5.83398 18.3333H14.1673C14.8304 18.3333 15.4662 18.0699 15.9351 17.6011C16.4039 17.1322 16.6673 16.4963 16.6673 15.8333V7.49996C16.6673 7.49996 16.6673 7.49996 16.6673 7.44996ZM11.6673 4.50829L13.8257 6.66663H12.5007C12.2796 6.66663 12.0677 6.57883 11.9114 6.42255C11.7551 6.26627 11.6673 6.05431 11.6673 5.83329V4.50829ZM15.0007 15.8333C15.0007 16.0543 14.9129 16.2663 14.7566 16.4225C14.6003 16.5788 14.3883 16.6666 14.1673 16.6666H5.83398C5.61297 16.6666 5.40101 16.5788 5.24473 16.4225C5.08845 16.2663 5.00065 16.0543 5.00065 15.8333V4.16663C5.00065 3.94561 5.08845 3.73365 5.24473 3.57737C5.40101 3.42109 5.61297 3.33329 5.83398 3.33329H10.0007V5.83329C10.0007 6.49633 10.264 7.13222 10.7329 7.60106C11.2017 8.0699 11.8376 8.33329 12.5007 8.33329H15.0007V15.8333Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'FileBlank';

export const FileBlank20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
