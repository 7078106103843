/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M11.3325 6.1133C11.2076 5.98913 11.0386 5.91943 10.8625 5.91943C10.6864 5.91943 10.5174 5.98913 10.3925 6.1133L7.99915 8.47329L5.63915 6.1133C5.51424 5.98913 5.34528 5.91943 5.16915 5.91943C4.99303 5.91943 4.82406 5.98913 4.69915 6.1133C4.63667 6.17527 4.58707 6.249 4.55322 6.33024C4.51938 6.41148 4.50195 6.49862 4.50195 6.58663C4.50195 6.67464 4.51938 6.76177 4.55322 6.84301C4.58707 6.92425 4.63667 6.99799 4.69915 7.05996L7.52582 9.88663C7.58779 9.94911 7.66153 9.99871 7.74277 10.0326C7.82401 10.0664 7.91114 10.0838 7.99915 10.0838C8.08716 10.0838 8.1743 10.0664 8.25554 10.0326C8.33678 9.99871 8.41051 9.94911 8.47249 9.88663L11.3325 7.05996C11.395 6.99799 11.4446 6.92425 11.4784 6.84301C11.5123 6.76177 11.5297 6.67464 11.5297 6.58663C11.5297 6.49862 11.5123 6.41148 11.4784 6.33024C11.4446 6.249 11.395 6.17527 11.3325 6.1133Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'AngleDown';

export const AngleDown16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
