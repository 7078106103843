/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_767_14769)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.86128 10.8619C5.12163 10.6015 5.54374 10.6015 5.80409 10.8619L7.99935 13.0572L10.1946 10.8619C10.455 10.6015 10.8771 10.6015 11.1374 10.8619C11.3978 11.1222 11.3978 11.5443 11.1374 11.8047L8.47075 14.4714C8.2104 14.7317 7.78829 14.7317 7.52794 14.4714L4.86128 11.8047C4.60093 11.5443 4.60093 11.1222 4.86128 10.8619Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00065 7.33337C8.36884 7.33337 8.66732 7.63185 8.66732 8.00004V14C8.66732 14.3682 8.36884 14.6667 8.00065 14.6667C7.63246 14.6667 7.33398 14.3682 7.33398 14V8.00004C7.33398 7.63185 7.63246 7.33337 8.00065 7.33337Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.77851 1.32944C6.6855 1.29682 7.58807 1.47039 8.41827 1.83707C9.24848 2.20375 9.98468 2.75399 10.5715 3.44636C11.0451 4.00527 11.4114 4.64503 11.6537 5.33333L11.9996 5.33333C12.8497 5.33278 13.6784 5.60306 14.3645 6.10498C15.0507 6.607 15.5591 7.31464 15.8159 8.12519C16.0727 8.93574 16.0646 9.80704 15.7926 10.6126C15.5207 11.4183 14.9991 12.1162 14.3036 12.6053C14.0024 12.8171 13.5866 12.7447 13.3748 12.4435C13.163 12.1423 13.2355 11.7265 13.5366 11.5147C14.0003 11.1886 14.348 10.7233 14.5293 10.1862C14.7106 9.64914 14.7161 9.06827 14.5448 8.5279C14.3736 7.98753 14.0347 7.51578 13.5772 7.1811C13.1197 6.84641 12.5675 6.66622 12.0006 6.66666H11.1601C10.8557 6.66666 10.59 6.46051 10.5144 6.16569C10.3389 5.48195 10.0107 4.84692 9.55427 4.30841C9.09789 3.76989 8.52529 3.34193 7.87958 3.05673C7.23386 2.77154 6.53186 2.63654 5.82643 2.66191C5.12099 2.68728 4.4305 2.87235 3.80694 3.20319C3.18338 3.53403 2.643 4.00202 2.22648 4.57193C1.80996 5.14183 1.52815 5.7988 1.40227 6.49338C1.2764 7.18796 1.30973 7.90204 1.49976 8.58187C1.68979 9.2617 2.03157 9.88957 2.49937 10.4182C2.74337 10.6939 2.71765 11.1152 2.44192 11.3593C2.16619 11.6033 1.74487 11.5775 1.50086 11.3018C0.899409 10.6221 0.459977 9.81488 0.21565 8.94082C-0.0286771 8.06675 -0.0715322 7.14864 0.090311 6.25561C0.252154 5.36259 0.614478 4.51791 1.15001 3.78517C1.68553 3.05244 2.38031 2.45074 3.18203 2.02537C3.98375 1.6 4.87152 1.36205 5.77851 1.32944Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_767_14769">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'DownloadCloud';

export const DownloadCloud16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
