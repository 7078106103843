/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99922 2.53333C8.44105 2.53333 8.79922 2.8915 8.79922 3.33333V12.6667C8.79922 13.1085 8.44105 13.4667 7.99922 13.4667C7.55739 13.4667 7.19922 13.1085 7.19922 12.6667V3.33333C7.19922 2.8915 7.55739 2.53333 7.99922 2.53333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.43418 2.76764C7.7466 2.45522 8.25314 2.45522 8.56556 2.76764L13.2322 7.43431C13.5446 7.74673 13.5446 8.25326 13.2322 8.56568C12.9198 8.8781 12.4133 8.8781 12.1009 8.56568L7.99987 4.4647L3.89889 8.56568C3.58647 8.8781 3.07994 8.8781 2.76752 8.56568C2.4551 8.25326 2.4551 7.74673 2.76752 7.43431L7.43418 2.76764Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'ArrowUp';

export const ArrowUp16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
