/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 6C8.5 5.44772 8.94772 5 9.5 5C10.0523 5 10.5 5.44772 10.5 6C10.5 6.55228 10.0523 7 9.5 7C8.94772 7 8.5 6.55228 8.5 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 6C1.5 5.44772 1.94772 5 2.5 5C3.05228 5 3.5 5.44772 3.5 6C3.5 6.55228 3.05228 7 2.5 7C1.94772 7 1.5 6.55228 1.5 6Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'MoreHorizontal';

export const MoreHorizontal12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
