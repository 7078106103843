/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.23223 7.73223C2.70107 7.26339 3.33696 7 4 7H8C8.66304 7 9.29893 7.26339 9.76777 7.73223C10.2366 8.20107 10.5 8.83696 10.5 9.5V10.5C10.5 10.7761 10.2761 11 10 11C9.72386 11 9.5 10.7761 9.5 10.5V9.5C9.5 9.10218 9.34196 8.72065 9.06066 8.43934C8.77936 8.15804 8.39782 8 8 8H4C3.60218 8 3.22064 8.15804 2.93934 8.43934C2.65804 8.72064 2.5 9.10218 2.5 9.5V10.5C2.5 10.7761 2.27614 11 2 11C1.72386 11 1.5 10.7761 1.5 10.5V9.5C1.5 8.83696 1.76339 8.20107 2.23223 7.73223Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.17157 2 4.5 2.67157 4.5 3.5C4.5 4.32843 5.17157 5 6 5C6.82843 5 7.5 4.32843 7.5 3.5C7.5 2.67157 6.82843 2 6 2ZM3.5 3.5C3.5 2.11929 4.61929 1 6 1C7.38071 1 8.5 2.11929 8.5 3.5C8.5 4.88071 7.38071 6 6 6C4.61929 6 3.5 4.88071 3.5 3.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'User';

export const User12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
