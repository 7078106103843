/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.49935 11.6667C2.95959 11.6667 3.33268 12.0398 3.33268 12.5V15.8334C3.33268 16.0544 3.42048 16.2663 3.57676 16.4226C3.73304 16.5789 3.945 16.6667 4.16602 16.6667H15.8327C16.0537 16.6667 16.2657 16.5789 16.4219 16.4226C16.5782 16.2663 16.666 16.0544 16.666 15.8334V12.5C16.666 12.0398 17.0391 11.6667 17.4993 11.6667C17.9596 11.6667 18.3327 12.0398 18.3327 12.5V15.8334C18.3327 16.4964 18.0693 17.1323 17.6005 17.6011C17.1316 18.07 16.4957 18.3334 15.8327 18.3334H4.16602C3.50297 18.3334 2.86709 18.07 2.39825 17.6011C1.92941 17.1323 1.66602 16.4964 1.66602 15.8334V12.5C1.66602 12.0398 2.03911 11.6667 2.49935 11.6667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 1.66669C10.4596 1.66669 10.8327 2.03978 10.8327 2.50002V12.5C10.8327 12.9603 10.4596 13.3334 9.99935 13.3334C9.53911 13.3334 9.16602 12.9603 9.16602 12.5V2.50002C9.16602 2.03978 9.53911 1.66669 9.99935 1.66669Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'Download';

export const Download20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
