/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 10C5.5 9.72386 5.72386 9.5 6 9.5H10.5C10.7761 9.5 11 9.72386 11 10C11 10.2761 10.7761 10.5 10.5 10.5H6C5.72386 10.5 5.5 10.2761 5.5 10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00001 1.93933C8.85131 1.93933 8.70871 1.9984 8.60356 2.10354L2.45149 8.25562L2.18719 9.31281L3.24439 9.04851L9.39646 2.89644C9.44852 2.84438 9.48982 2.78257 9.51799 2.71455C9.54617 2.64652 9.56067 2.57362 9.56067 2.49999C9.56067 2.42636 9.54617 2.35346 9.51799 2.28544C9.48982 2.21741 9.44852 2.15561 9.39646 2.10354C9.3444 2.05148 9.28259 2.01018 9.21457 1.98201C9.14654 1.95383 9.07364 1.93933 9.00001 1.93933ZM7.89646 1.39644C8.18914 1.10376 8.5861 0.939331 9.00001 0.939331C9.20496 0.939331 9.4079 0.979699 9.59725 1.05813C9.7866 1.13656 9.95864 1.25152 10.1036 1.39644C10.2485 1.54136 10.3634 1.7134 10.4419 1.90275C10.5203 2.0921 10.5607 2.29504 10.5607 2.49999C10.5607 2.70494 10.5203 2.90788 10.4419 3.09723C10.3634 3.28658 10.2485 3.45862 10.1036 3.60354L3.85356 9.85355C3.78948 9.91762 3.70919 9.96308 3.62128 9.98506L1.62128 10.4851C1.45089 10.5277 1.27065 10.4777 1.14646 10.3535C1.02227 10.2294 0.972342 10.0491 1.01494 9.87872L1.51494 7.87872C1.53692 7.79081 1.58238 7.71052 1.64646 7.64644L7.89646 1.39644Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'Edit';

export const Edit12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
