/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6ZM17.9342 10.3263L11.4692 16.7913L9.85293 15.1751L9.85304 15.1749L7.42878 12.7507L9.04503 11.1344L11.4693 13.5587L16.3179 8.71008L17.9342 10.3263Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6ZM17.126 9.51821C17.5723 9.96452 17.5723 10.6881 17.126 11.1345L12.2773 15.9832C11.831 16.4295 11.1074 16.4295 10.6611 15.9832L9.85299 15.1751C9.85296 15.1751 9.85296 15.175 9.85299 15.175C9.85302 15.175 9.85302 15.1749 9.85299 15.1749L8.23691 13.5588C7.79059 13.1125 7.79059 12.3889 8.23691 11.9426C8.68322 11.4963 9.40684 11.4963 9.85315 11.9426L10.2572 12.3466C10.9266 13.016 12.012 13.016 12.6814 12.3466L15.5098 9.51821C15.9561 9.07189 16.6797 9.07189 17.126 9.51821Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'CheckboxChecked';

export const CheckboxChecked24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
