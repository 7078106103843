/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33398 10C3.33398 9.53978 3.70708 9.16669 4.16732 9.16669H15.834C16.2942 9.16669 16.6673 9.53978 16.6673 10C16.6673 10.4603 16.2942 10.8334 15.834 10.8334H4.16732C3.70708 10.8334 3.33398 10.4603 3.33398 10Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'Minus';

export const Minus20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
