/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2189_20936)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33437 0.997048C5.01461 0.972585 5.69154 1.10276 6.31419 1.37777C6.93684 1.65278 7.489 2.06546 7.92908 2.58474C8.28433 3.00392 8.55908 3.48374 8.74079 3.99997L9.00019 3.99997C9.63775 3.99955 10.2593 4.20226 10.7738 4.5787C11.2885 4.95522 11.6698 5.48595 11.8624 6.09386C12.055 6.70178 12.0489 7.35525 11.845 7.95946C11.641 8.56366 11.2498 9.08715 10.7282 9.45397C10.5023 9.61281 10.1904 9.55846 10.0316 9.33258C9.87273 9.10669 9.92708 8.79481 10.153 8.63597C10.5007 8.39142 10.7615 8.04243 10.8975 7.63963C11.0335 7.23682 11.0375 6.80117 10.9091 6.3959C10.7807 5.99062 10.5265 5.6368 10.1834 5.38579C9.84028 5.13478 9.4261 4.99963 9.00097 4.99997H8.37058C8.1423 4.99997 7.943 4.84535 7.88627 4.62424C7.75468 4.11143 7.50848 3.63516 7.16619 3.23127C6.82391 2.82739 6.39446 2.50641 5.91017 2.29252C5.42588 2.07862 4.89939 1.97738 4.37031 1.9964C3.84123 2.01543 3.32337 2.15423 2.8557 2.40236C2.38803 2.65049 1.98274 3.00148 1.67035 3.42891C1.35796 3.85634 1.1466 4.34907 1.05219 4.87C0.957785 5.39094 0.982783 5.9265 1.12531 6.43637C1.26783 6.94625 1.52417 7.41714 1.87502 7.81361C2.05802 8.02041 2.03873 8.33641 1.83193 8.51941C1.62513 8.70241 1.30914 8.68312 1.12614 8.47632C0.675045 7.96657 0.345471 7.36113 0.162226 6.70558C-0.0210196 6.05003 -0.0531609 5.36145 0.0682215 4.69168C0.189604 4.02191 0.461347 3.3884 0.862993 2.83885C1.26464 2.2893 1.78572 1.83802 2.38701 1.519C2.9883 1.19997 3.65413 1.02151 4.33437 0.997048Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33203 6.00195C4.60817 6.00195 4.83203 6.20546 4.83203 6.4565V10.5474C4.83203 10.7984 4.60817 11.002 4.33203 11.002C4.05589 11.002 3.83203 10.7984 3.83203 10.5474V6.4565C3.83203 6.20546 4.05589 6.00195 4.33203 6.00195Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0954 7.93797C5.94915 8.07732 5.71205 8.07732 5.5658 7.93797L4.33268 6.76298L3.09956 7.93797C2.95332 8.07732 2.71621 8.07732 2.56997 7.93797C2.42373 7.79862 2.42373 7.57269 2.56997 7.43334L4.06789 6.00603C4.21413 5.86668 4.45124 5.86668 4.59748 6.00603L6.0954 7.43334C6.24164 7.57269 6.24164 7.79862 6.0954 7.93797Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53125 5.90149C7.80739 5.90149 8.03125 6.105 8.03125 6.35603V10.4469C8.03125 10.698 7.80739 10.9015 7.53125 10.9015C7.25511 10.9015 7.03125 10.698 7.03125 10.4469V6.35603C7.03125 6.105 7.25511 5.90149 7.53125 5.90149Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.76789 8.96547C5.91413 8.82612 6.15124 8.82612 6.29748 8.96547L7.5306 10.1405L8.76372 8.96547C8.90996 8.82612 9.14707 8.82612 9.29331 8.96547C9.43956 9.10482 9.43956 9.33075 9.29331 9.4701L7.7954 10.8974C7.64915 11.0368 7.41205 11.0368 7.2658 10.8974L5.76789 9.4701C5.62164 9.33075 5.62164 9.10482 5.76789 8.96547Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2189_20936">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'CloudArrows';

export const CloudArrows12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
