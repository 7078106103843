/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M9.5 2.75001H6.36L6.2 2.25001C6.09628 1.95663 5.90387 1.70276 5.64945 1.52359C5.39503 1.34442 5.09118 1.24881 4.78 1.25001H2.5C2.10218 1.25001 1.72064 1.40805 1.43934 1.68935C1.15804 1.97066 1 2.35219 1 2.75001V9.25001C1 9.64784 1.15804 10.0294 1.43934 10.3107C1.72064 10.592 2.10218 10.75 2.5 10.75H9.5C9.89782 10.75 10.2794 10.592 10.5607 10.3107C10.842 10.0294 11 9.64784 11 9.25001V4.25001C11 3.85219 10.842 3.47066 10.5607 3.18935C10.2794 2.90805 9.89782 2.75001 9.5 2.75001ZM10 9.25001C10 9.38262 9.94732 9.5098 9.85355 9.60357C9.75979 9.69733 9.63261 9.75001 9.5 9.75001H2.5C2.36739 9.75001 2.24021 9.69733 2.14645 9.60357C2.05268 9.5098 2 9.38262 2 9.25001V2.75001C2 2.6174 2.05268 2.49023 2.14645 2.39646C2.24021 2.30269 2.36739 2.25001 2.5 2.25001H4.78C4.88482 2.24974 4.98708 2.28242 5.07231 2.34343C5.15755 2.40444 5.22146 2.4907 5.255 2.59001L5.525 3.41001C5.55854 3.50932 5.62245 3.59558 5.70769 3.65659C5.79292 3.7176 5.89518 3.75028 6 3.75001H9.5C9.63261 3.75001 9.75979 3.80269 9.85355 3.89646C9.94732 3.99023 10 4.1174 10 4.25001V9.25001Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'Folder';

export const Folder12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
