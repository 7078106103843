/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_227_2439)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L12 13.4142L8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929L11.2929 11.2929Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11C12.5523 11 13 11.4477 13 12V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V12C11 11.4477 11.4477 11 12 11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66277 2.00854C10.0223 1.95846 11.3754 2.21721 12.6205 2.76536C13.8656 3.31351 14.9702 4.13678 15.8513 5.17329C16.5628 6.01022 17.1136 6.96858 17.4786 7.99998H17.9995C19.3325 8.00086 20.628 8.44564 21.6801 9.26412C22.7323 10.0826 23.482 11.2282 23.8108 12.52C24.1396 13.8119 24.0288 15.1764 23.496 16.3983C22.9631 17.6202 22.0385 18.6299 20.8681 19.268C20.3832 19.5323 19.7758 19.3535 19.5115 18.8686C19.2471 18.3837 19.4259 17.7763 19.9108 17.512C20.6911 17.0866 21.3075 16.4135 21.6627 15.5989C22.0179 14.7843 22.0918 13.8746 21.8726 13.0133C21.6534 12.1521 21.1536 11.3884 20.4521 10.8427C19.7507 10.2971 18.8875 10.0006 17.9988 9.99998H16.7395C16.2836 9.99998 15.8854 9.69166 15.7713 9.25029C15.5065 8.22588 15.0128 7.27483 14.3275 6.46866C13.6422 5.66249 12.783 5.02216 11.8146 4.59582C10.8462 4.16949 9.79379 3.96823 8.73641 4.00719C7.67903 4.04615 6.64424 4.3243 5.70984 4.82075C4.77543 5.31719 3.96573 6.01901 3.3416 6.87343C2.71747 7.72784 2.29516 8.71264 2.10641 9.75376C1.91767 10.7949 1.9674 11.8653 2.25187 12.8844C2.53634 13.9035 3.04814 14.8449 3.74881 15.6378C4.11452 16.0516 4.07551 16.6836 3.66167 17.0493C3.24782 17.415 2.61586 17.376 2.25014 16.9622C1.34929 15.9428 0.69125 14.7324 0.325503 13.4221C-0.0402432 12.1118 -0.104183 10.7356 0.138491 9.397C0.381165 8.05841 0.924137 6.79224 1.72659 5.69371C2.52904 4.59517 3.57009 3.69284 4.77146 3.05455C5.97284 2.41626 7.30328 2.05863 8.66277 2.00854Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L12 13.4142L8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929L11.2929 11.2929Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_227_2439">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'UploadCloud';

export const UploadCloud24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
