/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_767_14495)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1673 2.5C19.6276 2.5 20.0007 2.8731 20.0007 3.33333V8.33333C20.0007 8.79357 19.6276 9.16667 19.1673 9.16667H14.1673C13.7071 9.16667 13.334 8.79357 13.334 8.33333C13.334 7.8731 13.7071 7.5 14.1673 7.5H18.334V3.33333C18.334 2.8731 18.7071 2.5 19.1673 2.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.6666C0 11.2064 0.373096 10.8333 0.833333 10.8333H5.83333C6.29357 10.8333 6.66667 11.2064 6.66667 11.6666C6.66667 12.1269 6.29357 12.5 5.83333 12.5H1.66667V16.6666C1.66667 17.1269 1.29357 17.5 0.833333 17.5C0.373096 17.5 0 17.1269 0 16.6666V11.6666Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8478 3.59783C10.766 3.28492 9.62253 3.25115 8.52414 3.49967C7.42576 3.7482 6.40823 4.27091 5.5665 5.01905C4.72477 5.76719 4.08628 6.71636 3.7106 7.778C3.55707 8.21187 3.08088 8.43913 2.64701 8.2856C2.21313 8.13207 1.98587 7.65588 2.13941 7.22201C2.60901 5.89495 3.40712 4.70849 4.45928 3.77332C5.51144 2.83815 6.78335 2.18475 8.15634 1.8741C9.52932 1.56344 10.9586 1.60565 12.3109 1.9968C13.6588 2.38669 14.8864 3.11064 15.8799 4.10141L19.7373 7.72604C20.0727 8.0412 20.0891 8.56858 19.774 8.90398C19.4588 9.23938 18.9314 9.25579 18.596 8.94063L14.7294 5.3073C14.723 5.30133 14.7168 5.29527 14.7106 5.28911C13.9145 4.4926 12.9296 3.91074 11.8478 3.59783ZM0.226042 11.096C0.541201 10.7606 1.06858 10.7442 1.40398 11.0594L5.27065 14.6927C5.277 14.6987 5.28325 14.7047 5.28941 14.7109C6.08552 15.5074 7.07042 16.0893 8.15223 16.4022C9.23403 16.7151 10.3775 16.7489 11.4759 16.5003C12.5742 16.2518 13.5918 15.7291 14.4335 14.981C15.2752 14.2328 15.9137 13.2836 16.2894 12.222C16.4429 11.7881 16.9191 11.5609 17.353 11.7144C17.7869 11.8679 18.0141 12.3441 17.8606 12.778C17.391 14.1051 16.5929 15.2915 15.5407 16.2267C14.4886 17.1619 13.2166 17.8153 11.8437 18.1259C10.4707 18.4366 9.04138 18.3944 7.68913 18.0032C6.34117 17.6133 5.11356 16.8894 4.12009 15.8986L0.262689 12.274C-0.0727107 11.9588 -0.089118 11.4314 0.226042 11.096Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_767_14495">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'RefreshCw';

export const RefreshCw20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
