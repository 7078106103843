/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.334 7.99996C11.334 7.26358 11.9309 6.66663 12.6673 6.66663C13.4037 6.66663 14.0007 7.26358 14.0007 7.99996C14.0007 8.73634 13.4037 9.33329 12.6673 9.33329C11.9309 9.33329 11.334 8.73634 11.334 7.99996Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66602 7.99996C6.66602 7.26358 7.26297 6.66663 7.99935 6.66663C8.73573 6.66663 9.33268 7.26358 9.33268 7.99996C9.33268 8.73634 8.73573 9.33329 7.99935 9.33329C7.26297 9.33329 6.66602 8.73634 6.66602 7.99996Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7.99996C2 7.26358 2.59695 6.66663 3.33333 6.66663C4.06971 6.66663 4.66667 7.26358 4.66667 7.99996C4.66667 8.73634 4.06971 9.33329 3.33333 9.33329C2.59695 9.33329 2 8.73634 2 7.99996Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'MoreHorizontal';

export const MoreHorizontal16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
