/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M12.3574 9.40837L8.8241 5.87504C8.74663 5.79693 8.65446 5.73494 8.55291 5.69263C8.45136 5.65032 8.34244 5.62854 8.23243 5.62854C8.12242 5.62854 8.0135 5.65032 7.91195 5.69263C7.8104 5.73494 7.71823 5.79693 7.64076 5.87504C7.48556 6.03117 7.39844 6.24238 7.39844 6.46254C7.39844 6.68269 7.48556 6.8939 7.64076 7.05004L10.5908 10L7.64076 12.95C7.48556 13.1062 7.39844 13.3174 7.39844 13.5375C7.39844 13.7577 7.48556 13.9689 7.64076 14.125C7.71863 14.2023 7.81098 14.2634 7.91251 14.3048C8.01404 14.3463 8.12276 14.3673 8.23243 14.3667C8.3421 14.3673 8.45082 14.3463 8.55235 14.3048C8.65388 14.2634 8.74623 14.2023 8.8241 14.125L12.3574 10.5917C12.4355 10.5142 12.4975 10.4221 12.5398 10.3205C12.5821 10.219 12.6039 10.11 12.6039 10C12.6039 9.89003 12.5821 9.78111 12.5398 9.67956C12.4975 9.57801 12.4355 9.48584 12.3574 9.40837Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'AngleRight';

export const AngleRight20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
