/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M15.29 16.29L13 18.59V7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V18.59L8.71 16.29C8.5217 16.1017 8.2663 15.9959 8 15.9959C7.7337 15.9959 7.4783 16.1017 7.29 16.29C7.1017 16.4783 6.99591 16.7337 6.99591 17C6.99591 17.2663 7.1017 17.5217 7.29 17.71L11.29 21.71C11.3851 21.801 11.4972 21.8724 11.62 21.92C11.7397 21.9729 11.8691 22.0002 12 22.0002C12.1309 22.0002 12.2603 21.9729 12.38 21.92C12.5028 21.8724 12.6149 21.801 12.71 21.71L16.71 17.71C16.8983 17.5217 17.0041 17.2663 17.0041 17C17.0041 16.7337 16.8983 16.4783 16.71 16.29C16.6168 16.1968 16.5061 16.1228 16.3842 16.0723C16.2624 16.0219 16.1319 15.9959 16 15.9959C15.7337 15.9959 15.4783 16.1017 15.29 16.29ZM19 2H5C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3C4 3.26522 4.10536 3.51957 4.29289 3.70711C4.48043 3.89464 4.73478 4 5 4H19C19.2652 4 19.5196 3.89464 19.7071 3.70711C19.8946 3.51957 20 3.26522 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'ArrowFromTop';

export const ArrowFromTop24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
