/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M8.49985 4.58497C8.40617 4.49185 8.27945 4.43958 8.14735 4.43958C8.01526 4.43958 7.88853 4.49185 7.79485 4.58497L5.99985 6.35497L4.22985 4.58497C4.13617 4.49185 4.00945 4.43958 3.87735 4.43958C3.74526 4.43958 3.61853 4.49185 3.52485 4.58497C3.47799 4.63145 3.44079 4.68675 3.41541 4.74768C3.39002 4.80861 3.37695 4.87397 3.37695 4.93997C3.37695 5.00598 3.39002 5.07133 3.41541 5.13226C3.44079 5.19319 3.47799 5.24849 3.52485 5.29497L5.64485 7.41497C5.69133 7.46184 5.74663 7.49903 5.80756 7.52442C5.86849 7.5498 5.93385 7.56287 5.99985 7.56287C6.06586 7.56287 6.13121 7.5498 6.19214 7.52442C6.25307 7.49903 6.30837 7.46184 6.35485 7.41497L8.49985 5.29497C8.54672 5.24849 8.58391 5.19319 8.6093 5.13226C8.63468 5.07133 8.64775 5.00598 8.64775 4.93997C8.64775 4.87397 8.63468 4.80861 8.6093 4.74768C8.58391 4.68675 8.54672 4.63145 8.49985 4.58497Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'AngleDown';

export const AngleDown12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
