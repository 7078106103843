/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M9.99935 14.1666C11.1521 14.1666 12.1348 13.7604 12.9473 12.9479C13.7598 12.1354 14.166 11.1527 14.166 9.99996C14.166 8.84718 13.7598 7.86454 12.9473 7.05204C12.1348 6.23954 11.1521 5.83329 9.99935 5.83329C8.84657 5.83329 7.86393 6.23954 7.05143 7.05204C6.23893 7.86454 5.83268 8.84718 5.83268 9.99996C5.83268 11.1527 6.23893 12.1354 7.05143 12.9479C7.86393 13.7604 8.84657 14.1666 9.99935 14.1666ZM9.99935 18.3333C8.84657 18.3333 7.76324 18.1145 6.74935 17.677C5.73546 17.2395 4.85352 16.6458 4.10352 15.8958C3.35352 15.1458 2.75977 14.2638 2.32227 13.25C1.88477 12.2361 1.66602 11.1527 1.66602 9.99996C1.66602 8.84718 1.88477 7.76385 2.32227 6.74996C2.75977 5.73607 3.35352 4.85413 4.10352 4.10413C4.85352 3.35413 5.73546 2.76038 6.74935 2.32288C7.76324 1.88538 8.84657 1.66663 9.99935 1.66663C11.1521 1.66663 12.2355 1.88538 13.2494 2.32288C14.2632 2.76038 15.1452 3.35413 15.8952 4.10413C16.6452 4.85413 17.2389 5.73607 17.6764 6.74996C18.1139 7.76385 18.3327 8.84718 18.3327 9.99996C18.3327 11.1527 18.1139 12.2361 17.6764 13.25C17.2389 14.2638 16.6452 15.1458 15.8952 15.8958C15.1452 16.6458 14.2632 17.2395 13.2494 17.677C12.2355 18.1145 11.1521 18.3333 9.99935 18.3333ZM9.99935 16.6666C11.8605 16.6666 13.4369 16.0208 14.7285 14.7291C16.0202 13.4375 16.666 11.8611 16.666 9.99996C16.666 8.13885 16.0202 6.56246 14.7285 5.27079C13.4369 3.97913 11.8605 3.33329 9.99935 3.33329C8.13824 3.33329 6.56185 3.97913 5.27018 5.27079C3.97852 6.56246 3.33268 8.13885 3.33268 9.99996C3.33268 11.8611 3.97852 13.4375 5.27018 14.7291C6.56185 16.0208 8.13824 16.6666 9.99935 16.6666Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'RadioButtonChecked';

export const RadioButtonChecked20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
