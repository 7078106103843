/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_768_9896)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33398 6.66671C6.96579 6.66671 6.66732 6.96518 6.66732 7.33337V13.3334C6.66732 13.7016 6.96579 14 7.33398 14H13.334C13.7022 14 14.0007 13.7016 14.0007 13.3334V7.33337C14.0007 6.96518 13.7022 6.66671 13.334 6.66671H7.33398ZM5.33398 7.33337C5.33398 6.2288 6.22941 5.33337 7.33398 5.33337H13.334C14.4386 5.33337 15.334 6.2288 15.334 7.33337V13.3334C15.334 14.4379 14.4386 15.3334 13.334 15.3334H7.33398C6.22941 15.3334 5.33398 14.4379 5.33398 13.3334V7.33337Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66602 1.99996C2.4892 1.99996 2.31964 2.0702 2.19461 2.19522C2.06959 2.32025 1.99935 2.48981 1.99935 2.66663V8.66663C1.99935 8.84344 2.06959 9.01301 2.19461 9.13803C2.31964 9.26305 2.4892 9.33329 2.66602 9.33329H3.33268C3.70087 9.33329 3.99935 9.63177 3.99935 9.99996C3.99935 10.3681 3.70087 10.6666 3.33268 10.6666H2.66602C2.13558 10.6666 1.62687 10.4559 1.2518 10.0808C0.876729 9.70577 0.666016 9.19706 0.666016 8.66663V2.66663C0.666016 2.13619 0.876729 1.62749 1.2518 1.25241C1.62687 0.87734 2.13558 0.666626 2.66602 0.666626H8.66602C9.19645 0.666626 9.70516 0.87734 10.0802 1.25241C10.4553 1.62748 10.666 2.13619 10.666 2.66663V3.33329C10.666 3.70148 10.3675 3.99996 9.99935 3.99996C9.63116 3.99996 9.33268 3.70148 9.33268 3.33329V2.66663C9.33268 2.48982 9.26244 2.32025 9.13742 2.19522C9.0124 2.0702 8.84283 1.99996 8.66602 1.99996H2.66602Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_768_9896">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'Copy';

export const Copy16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
