/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33398 13.3333C5.33398 12.9651 5.63246 12.6666 6.00065 12.6666H10.0007C10.3688 12.6666 10.6673 12.9651 10.6673 13.3333C10.6673 13.7015 10.3688 14 10.0007 14H6.00065C5.63246 14 5.33398 13.7015 5.33398 13.3333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.66667C2 2.29848 2.29848 2 2.66667 2H13.3333C13.7015 2 14 2.29848 14 2.66667V4.66667C14 5.03486 13.7015 5.33333 13.3333 5.33333C12.9651 5.33333 12.6667 5.03486 12.6667 4.66667V3.33333H3.33333V4.66667C3.33333 5.03486 3.03486 5.33333 2.66667 5.33333C2.29848 5.33333 2 5.03486 2 4.66667V2.66667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00065 2C8.36884 2 8.66732 2.29848 8.66732 2.66667V13.3333C8.66732 13.7015 8.36884 14 8.00065 14C7.63246 14 7.33398 13.7015 7.33398 13.3333V2.66667C7.33398 2.29848 7.63246 2 8.00065 2Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'Type';

export const Type16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
