/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_768_9946)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64645 5.64645C5.84171 5.45118 6.15829 5.45118 6.35355 5.64645L8.35355 7.64645C8.54882 7.84171 8.54882 8.15829 8.35355 8.35355C8.15829 8.54882 7.84171 8.54882 7.64645 8.35355L6 6.70711L4.35355 8.35355C4.15829 8.54882 3.84171 8.54882 3.64645 8.35355C3.45118 8.15829 3.45118 7.84171 3.64645 7.64645L5.64645 5.64645Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.5C6.27614 5.5 6.5 5.72386 6.5 6V10.5C6.5 10.7761 6.27614 11 6 11C5.72386 11 5.5 10.7761 5.5 10.5V6C5.5 5.72386 5.72386 5.5 6 5.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33236 1.00427C5.0121 0.979228 5.68868 1.10861 6.31123 1.38268C6.93377 1.65675 7.48608 2.06839 7.92664 2.58665C8.28237 3.00511 8.55776 3.48429 8.74025 3.99999H9.00071C9.66723 4.00043 10.315 4.22282 10.841 4.63206C11.3671 5.0413 11.742 5.61408 11.9064 6.26001C12.0708 6.90593 12.0154 7.58821 11.749 8.19917C11.4825 8.81012 11.0202 9.31496 10.435 9.63399C10.1926 9.76617 9.88889 9.67677 9.75671 9.43432C9.62453 9.19187 9.71393 8.88817 9.95638 8.75599C10.3465 8.5433 10.6547 8.20674 10.8323 7.79944C11.0099 7.39214 11.0469 6.93728 10.9373 6.50667C10.8277 6.07605 10.5778 5.6942 10.2271 5.42137C9.87632 5.14854 9.44473 5.00029 9.00038 4.99999H8.37071C8.14277 4.99999 7.94368 4.84583 7.88663 4.62515C7.7542 4.11294 7.50739 3.63742 7.16473 3.23433C6.82208 2.83124 6.3925 2.51108 5.9083 2.29791C5.4241 2.08474 4.89787 1.98412 4.36918 2.00359C3.84049 2.02307 3.3231 2.16215 2.85589 2.41037C2.38869 2.6586 1.98384 3.0095 1.67178 3.43671C1.35971 3.86392 1.14856 4.35632 1.05418 4.87688C0.95981 5.39744 0.984675 5.93263 1.12691 6.4422C1.26915 6.95177 1.52505 7.42246 1.87538 7.8189C2.05824 8.02582 2.03873 8.3418 1.83181 8.52466C1.62489 8.70752 1.30891 8.68801 1.12605 8.48109C0.675619 7.97139 0.346602 7.36621 0.163728 6.71105C-0.0191451 6.05589 -0.051115 5.36779 0.0702219 4.6985C0.191559 4.0292 0.463045 3.39612 0.864271 2.84685C1.2655 2.29758 1.78602 1.84642 2.38671 1.52728C2.9874 1.20813 3.65262 1.02932 4.33236 1.00427Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64645 5.64645C5.84171 5.45118 6.15829 5.45118 6.35355 5.64645L8.35355 7.64645C8.54882 7.84171 8.54882 8.15829 8.35355 8.35355C8.15829 8.54882 7.84171 8.54882 7.64645 8.35355L6 6.70711L4.35355 8.35355C4.15829 8.54882 3.84171 8.54882 3.64645 8.35355C3.45118 8.15829 3.45118 7.84171 3.64645 7.64645L5.64645 5.64645Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_768_9946">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'UploadCloud';

export const UploadCloud12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
