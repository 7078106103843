/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M3 15H13C13.2652 15 13.5196 14.8946 13.7071 14.7071C13.8946 14.5196 14 14.2652 14 14C14 13.7348 13.8946 13.4804 13.7071 13.2929C13.5196 13.1054 13.2652 13 13 13H3C2.73478 13 2.48043 13.1054 2.29289 13.2929C2.10536 13.4804 2 13.7348 2 14C2 14.2652 2.10536 14.5196 2.29289 14.7071C2.48043 14.8946 2.73478 15 3 15ZM3 11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9H3C2.73478 9 2.48043 9.10536 2.29289 9.29289C2.10536 9.48043 2 9.73478 2 10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11ZM3 7H21C21.2652 7 21.5196 6.89464 21.7071 6.70711C21.8946 6.51957 22 6.26522 22 6C22 5.73478 21.8946 5.48043 21.7071 5.29289C21.5196 5.10536 21.2652 5 21 5H3C2.73478 5 2.48043 5.10536 2.29289 5.29289C2.10536 5.48043 2 5.73478 2 6C2 6.26522 2.10536 6.51957 2.29289 6.70711C2.48043 6.89464 2.73478 7 3 7ZM21 17H3C2.73478 17 2.48043 17.1054 2.29289 17.2929C2.10536 17.4804 2 17.7348 2 18C2 18.2652 2.10536 18.5196 2.29289 18.7071C2.48043 18.8946 2.73478 19 3 19H21C21.2652 19 21.5196 18.8946 21.7071 18.7071C21.8946 18.5196 22 18.2652 22 18C22 17.7348 21.8946 17.4804 21.7071 17.2929C21.5196 17.1054 21.2652 17 21 17Z"
        fill="currentColor"
      />
      <path
        d="M20.3068 14.4094C20.4055 14.5049 20.5229 14.579 20.6516 14.6272C20.7803 14.6753 20.9175 14.6964 21.0547 14.6892C21.1919 14.6819 21.3261 14.6465 21.449 14.5851C21.5719 14.5237 21.6808 14.4376 21.7689 14.3322C21.8571 14.2268 21.9226 14.1044 21.9613 13.9726C22.0001 13.8408 22.0113 13.7025 21.9942 13.5661C21.9771 13.4298 21.9321 13.2985 21.862 13.1803C21.7919 13.0622 21.6982 12.9597 21.5868 12.8794L20.5068 11.9794L21.5868 11.0794C21.688 10.9954 21.7717 10.8923 21.8331 10.776C21.8944 10.6596 21.9322 10.5323 21.9443 10.4014C21.9564 10.2704 21.9425 10.1383 21.9035 10.0127C21.8644 9.88711 21.801 9.77044 21.7168 9.66939C21.6228 9.55651 21.505 9.46573 21.3719 9.40351C21.2388 9.34129 21.0937 9.30915 20.9468 9.30939C20.7119 9.31174 20.4853 9.3967 20.3068 9.54939L18.3068 11.2094C18.1935 11.3032 18.1024 11.4209 18.0398 11.554C17.9773 11.6871 17.9448 11.8323 17.9448 11.9794C17.9448 12.1265 17.9773 12.2717 18.0398 12.4048C18.1024 12.5379 18.1935 12.6556 18.3068 12.7494L20.3068 14.4094Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'AlignLess';

export const AlignLess24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
