/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M13.3327 5.96004C13.3257 5.8988 13.3123 5.83846 13.2927 5.78004V5.72004C13.2606 5.65149 13.2179 5.58848 13.166 5.53337L9.16602 1.53337C9.11091 1.48152 9.0479 1.43876 8.97935 1.40671H8.91935C8.85162 1.36787 8.77683 1.34294 8.69935 1.33337H4.66602C4.13558 1.33337 3.62687 1.54409 3.2518 1.91916C2.87673 2.29423 2.66602 2.80294 2.66602 3.33337V12.6667C2.66602 13.1971 2.87673 13.7058 3.2518 14.0809C3.62687 14.456 4.13558 14.6667 4.66602 14.6667H11.3327C11.8631 14.6667 12.3718 14.456 12.7469 14.0809C13.122 13.7058 13.3327 13.1971 13.3327 12.6667V6.00004C13.3327 6.00004 13.3327 6.00004 13.3327 5.96004ZM9.33268 3.60671L11.0593 5.33337H9.99935C9.82254 5.33337 9.65297 5.26314 9.52794 5.13811C9.40292 5.01309 9.33268 4.84352 9.33268 4.66671V3.60671ZM11.9993 12.6667C11.9993 12.8435 11.9291 13.0131 11.8041 13.1381C11.6791 13.2631 11.5095 13.3334 11.3327 13.3334H4.66602C4.4892 13.3334 4.31964 13.2631 4.19461 13.1381C4.06959 13.0131 3.99935 12.8435 3.99935 12.6667V3.33337C3.99935 3.15656 4.06959 2.98699 4.19461 2.86197C4.31964 2.73695 4.4892 2.66671 4.66602 2.66671H7.99935V4.66671C7.99935 5.19714 8.21006 5.70585 8.58513 6.08092C8.96021 6.45599 9.46892 6.66671 9.99935 6.66671H11.9993V12.6667Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'FileBlank';

export const FileBlank16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
