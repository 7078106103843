/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00039 1.90002C6.33176 1.90002 6.60039 2.16865 6.60039 2.50002V9.50002C6.60039 9.8314 6.33176 10.1 6.00039 10.1C5.66902 10.1 5.40039 9.8314 5.40039 9.50002V2.50002C5.40039 2.16865 5.66902 1.90002 6.00039 1.90002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57613 2.07576C5.81044 1.84145 6.19034 1.84145 6.42465 2.07576L9.92465 5.57576C10.159 5.81008 10.159 6.18997 9.92465 6.42429C9.69034 6.6586 9.31044 6.6586 9.07613 6.42429L6.00039 3.34855L2.92465 6.42429C2.69034 6.6586 2.31044 6.6586 2.07613 6.42429C1.84181 6.18997 1.84181 5.81008 2.07613 5.57576L5.57613 2.07576Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'ArrowUp';

export const ArrowUp12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
