/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M9.955 7.755H7.69C7.55739 7.755 7.43021 7.80768 7.33645 7.90145C7.24268 7.99521 7.19 8.12239 7.19 8.255C7.19 8.38761 7.24268 8.51478 7.33645 8.60855C7.43021 8.70232 7.55739 8.755 7.69 8.755H8.89C8.33847 9.33137 7.6272 9.72966 6.84758 9.8987C6.06796 10.0677 5.2556 9.9998 4.51489 9.70362C3.77417 9.40744 3.13893 8.89655 2.6908 8.23659C2.24266 7.57662 2.00209 6.79773 2 6C2 5.86739 1.94732 5.74021 1.85355 5.64645C1.75979 5.55268 1.63261 5.5 1.5 5.5C1.36739 5.5 1.24021 5.55268 1.14645 5.64645C1.05268 5.74021 1 5.86739 1 6C1.00264 6.9764 1.29111 7.93066 1.8298 8.74501C2.36849 9.55937 3.13383 10.1982 4.03137 10.5826C4.92891 10.967 5.91937 11.0803 6.88051 10.9083C7.84165 10.7364 8.73142 10.2868 9.44 9.615V10.5C9.44 10.6326 9.49268 10.7598 9.58645 10.8536C9.68021 10.9473 9.80739 11 9.94 11C10.0726 11 10.1998 10.9473 10.2936 10.8536C10.3873 10.7598 10.44 10.6326 10.44 10.5V8.25C10.4388 8.12082 10.3876 7.99713 10.2972 7.90485C10.2068 7.81257 10.0841 7.75887 9.955 7.755ZM6 1C4.71819 1.00366 3.48675 1.49946 2.56 2.385V1.5C2.56 1.36739 2.50732 1.24021 2.41355 1.14645C2.31979 1.05268 2.19261 1 2.06 1C1.92739 1 1.80021 1.05268 1.70645 1.14645C1.61268 1.24021 1.56 1.36739 1.56 1.5V3.75C1.56 3.88261 1.61268 4.00979 1.70645 4.10355C1.80021 4.19732 1.92739 4.25 2.06 4.25H4.31C4.44261 4.25 4.56979 4.19732 4.66355 4.10355C4.75732 4.00979 4.81 3.88261 4.81 3.75C4.81 3.61739 4.75732 3.49021 4.66355 3.39645C4.56979 3.30268 4.44261 3.25 4.31 3.25H3.11C3.66123 2.67394 4.37205 2.27575 5.1512 2.10655C5.93035 1.93736 6.74229 2.00487 7.4828 2.30043C8.22331 2.59599 8.8586 3.10611 9.30712 3.7653C9.75564 4.4245 9.99692 5.20269 10 6C10 6.13261 10.0527 6.25979 10.1464 6.35355C10.2402 6.44732 10.3674 6.5 10.5 6.5C10.6326 6.5 10.7598 6.44732 10.8536 6.35355C10.9473 6.25979 11 6.13261 11 6C11 5.34339 10.8707 4.69321 10.6194 4.08658C10.3681 3.47995 9.99983 2.92876 9.53553 2.46447C9.07124 2.00017 8.52005 1.63188 7.91342 1.3806C7.30679 1.12933 6.65661 1 6 1Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'Sync';

export const Sync12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
