/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.16669C10.5523 3.16669 11 3.6144 11 4.16669V15.8334C11 16.3856 10.5523 16.8334 10 16.8334C9.44772 16.8334 9 16.3856 9 15.8334V4.16669C9 3.6144 9.44772 3.16669 10 3.16669Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5398 16.5404C10.1493 16.9309 9.5161 16.9309 9.12558 16.5404L3.29224 10.7071C2.90172 10.3166 2.90172 9.6834 3.29224 9.29287C3.68277 8.90235 4.31593 8.90235 4.70646 9.29287L9.83268 14.4191L14.9589 9.29287C15.3494 8.90235 15.9826 8.90235 16.3731 9.29287C16.7636 9.6834 16.7636 10.3166 16.3731 10.7071L10.5398 16.5404Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'ArrowDown';

export const ArrowDown20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
