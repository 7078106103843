/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_768_9908)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2ZM13.9342 6.32633L7.46918 12.7913L5.85293 11.1751L5.85304 11.1749L3.42878 8.75069L5.04503 7.13445L7.46929 9.55871L12.3179 4.71008L13.9342 6.32633Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2ZM13.126 5.51821C13.5723 5.96452 13.5723 6.68814 13.126 7.13445L8.2773 11.9832C7.83098 12.4295 7.10737 12.4295 6.66105 11.9832L5.85299 11.1751C5.85296 11.1751 5.85296 11.175 5.85299 11.175C5.85302 11.175 5.85302 11.1749 5.85299 11.1749L4.23691 9.55881C3.79059 9.1125 3.79059 8.38888 4.23691 7.94257C4.68322 7.49626 5.40684 7.49626 5.85315 7.94257L6.25716 8.34658C6.9266 9.01602 8.01198 9.01602 8.68142 8.34657L11.5098 5.51821C11.9561 5.07189 12.6797 5.07189 13.126 5.51821Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_768_9908">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'CheckboxChecked';

export const CheckboxChecked16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
