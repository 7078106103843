/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.16669C10.5523 3.16669 11 3.6144 11 4.16669V15.8334C11 16.3856 10.5523 16.8334 10 16.8334C9.44772 16.8334 9 16.3856 9 15.8334V4.16669C9 3.6144 9.44772 3.16669 10 3.16669Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29224 3.45958C9.68277 3.06906 10.3159 3.06906 10.7065 3.45958L16.5398 9.29291C16.9303 9.68344 16.9303 10.3166 16.5398 10.7071C16.1493 11.0977 15.5161 11.0977 15.1256 10.7071L9.99935 5.5809L4.87312 10.7071C4.4826 11.0977 3.84943 11.0977 3.45891 10.7071C3.06838 10.3166 3.06838 9.68344 3.45891 9.29291L9.29224 3.45958Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'ArrowUp';

export const ArrowUp20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
