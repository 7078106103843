/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M10 4.47C9.99479 4.42407 9.98474 4.37882 9.97 4.335V4.29C9.94596 4.23859 9.91389 4.19133 9.875 4.15L6.875 1.15C6.83367 1.11111 6.78641 1.07904 6.735 1.055H6.69C6.63921 1.02587 6.58311 1.00717 6.525 1H3.5C3.10218 1 2.72064 1.15804 2.43934 1.43934C2.15804 1.72064 2 2.10218 2 2.5V9.5C2 9.89782 2.15804 10.2794 2.43934 10.5607C2.72064 10.842 3.10218 11 3.5 11H8.5C8.89782 11 9.27936 10.842 9.56066 10.5607C9.84196 10.2794 10 9.89782 10 9.5V4.5C10 4.5 10 4.5 10 4.47ZM7 2.705L8.295 4H7.5C7.36739 4 7.24021 3.94732 7.14645 3.85355C7.05268 3.75979 7 3.63261 7 3.5V2.705ZM9 9.5C9 9.63261 8.94732 9.75979 8.85355 9.85355C8.75979 9.94732 8.63261 10 8.5 10H3.5C3.36739 10 3.24021 9.94732 3.14645 9.85355C3.05268 9.75979 3 9.63261 3 9.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H6V3.5C6 3.89782 6.15804 4.27936 6.43934 4.56066C6.72064 4.84196 7.10218 5 7.5 5H9V9.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'FileBlank';

export const FileBlank12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
