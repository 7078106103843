/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_768_9955)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.14645 0.146447C8.34171 -0.0488155 8.65829 -0.0488155 8.85355 0.146447L10.8536 2.14645C11.0488 2.34171 11.0488 2.65829 10.8536 2.85355L8.85355 4.85355C8.65829 5.04882 8.34171 5.04882 8.14645 4.85355C7.95118 4.65829 7.95118 4.34171 8.14645 4.14645L9.79289 2.5L8.14645 0.853553C7.95118 0.658291 7.95118 0.341709 8.14645 0.146447Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3C3.10218 3 2.72064 3.15804 2.43934 3.43934C2.15804 3.72064 2 4.10218 2 4.5V5.5C2 5.77614 1.77614 6 1.5 6C1.22386 6 1 5.77614 1 5.5V4.5C1 3.83696 1.26339 3.20107 1.73223 2.73223C2.20107 2.26339 2.83696 2 3.5 2H10.5C10.7761 2 11 2.22386 11 2.5C11 2.77614 10.7761 3 10.5 3H3.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.85355 7.14645C4.04882 7.34171 4.04882 7.65829 3.85355 7.85355L2.20711 9.5L3.85355 11.1464C4.04882 11.3417 4.04882 11.6583 3.85355 11.8536C3.65829 12.0488 3.34171 12.0488 3.14645 11.8536L1.14645 9.85355C0.951184 9.65829 0.951184 9.34171 1.14645 9.14645L3.14645 7.14645C3.34171 6.95118 3.65829 6.95118 3.85355 7.14645Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 6C10.7761 6 11 6.22386 11 6.5V7.5C11 8.16304 10.7366 8.79893 10.2678 9.26777C9.79893 9.73661 9.16304 10 8.5 10H1.5C1.22386 10 1 9.77614 1 9.5C1 9.22386 1.22386 9 1.5 9H8.5C8.89782 9 9.27936 8.84196 9.56066 8.56066C9.84196 8.27935 10 7.89782 10 7.5V6.5C10 6.22386 10.2239 6 10.5 6Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_768_9955">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'Repeat';

export const Repeat12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
