/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66602 2.66671C2.4892 2.66671 2.31964 2.73695 2.19461 2.86197C2.06959 2.98699 1.99935 3.15656 1.99935 3.33337V12.6667C1.99935 12.8435 2.06959 13.0131 2.19461 13.1381C2.31964 13.2631 2.4892 13.3334 2.66602 13.3334H13.3327C13.5095 13.3334 13.6791 13.2631 13.8041 13.1381C13.9291 13.0131 13.9993 12.8435 13.9993 12.6667V5.33337C13.9993 5.15656 13.9291 4.98699 13.8041 4.86197C13.6791 4.73695 13.5095 4.66671 13.3327 4.66671H7.33268C7.10978 4.66671 6.90163 4.55531 6.77798 4.36984L5.64256 2.66671H2.66602ZM1.2518 1.91916C1.62687 1.54409 2.13558 1.33337 2.66602 1.33337H5.99935C6.22225 1.33337 6.43041 1.44477 6.55405 1.63024L7.68947 3.33337H13.3327C13.8631 3.33337 14.3718 3.54409 14.7469 3.91916C15.122 4.29423 15.3327 4.80294 15.3327 5.33337V12.6667C15.3327 13.1971 15.122 13.7058 14.7469 14.0809C14.3718 14.456 13.8631 14.6667 13.3327 14.6667H2.66602C2.13558 14.6667 1.62687 14.456 1.2518 14.0809C0.876729 13.7058 0.666016 13.1971 0.666016 12.6667V3.33337C0.666016 2.80294 0.876729 2.29423 1.2518 1.91916Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00065 6.66663C8.36884 6.66663 8.66732 6.9651 8.66732 7.33329V11.3333C8.66732 11.7015 8.36884 12 8.00065 12C7.63246 12 7.33398 11.7015 7.33398 11.3333V7.33329C7.33398 6.9651 7.63246 6.66663 8.00065 6.66663Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33398 9.33329C5.33398 8.9651 5.63246 8.66663 6.00065 8.66663H10.0007C10.3688 8.66663 10.6673 8.9651 10.6673 9.33329C10.6673 9.70148 10.3688 9.99996 10.0007 9.99996H6.00065C5.63246 9.99996 5.33398 9.70148 5.33398 9.33329Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'FolderPlus';

export const FolderPlus16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
