/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_768_9942)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99935 1.99996C4.68564 1.99996 1.99935 4.68625 1.99935 7.99996C1.99935 11.3137 4.68564 14 7.99935 14C11.3131 14 13.9993 11.3137 13.9993 7.99996C13.9993 4.68625 11.3131 1.99996 7.99935 1.99996ZM0.666016 7.99996C0.666016 3.94987 3.94926 0.666626 7.99935 0.666626C12.0494 0.666626 15.3327 3.94987 15.3327 7.99996C15.3327 12.05 12.0494 15.3333 7.99935 15.3333C3.94926 15.3333 0.666016 12.05 0.666016 7.99996Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00065 7.33337C8.36884 7.33337 8.66732 7.63185 8.66732 8.00004V10.6667C8.66732 11.0349 8.36884 11.3334 8.00065 11.3334C7.63246 11.3334 7.33398 11.0349 7.33398 10.6667V8.00004C7.33398 7.63185 7.63246 7.33337 8.00065 7.33337Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33398 5.33329C7.33398 4.9651 7.63246 4.66663 8.00065 4.66663H8.00732C8.37551 4.66663 8.67398 4.9651 8.67398 5.33329C8.67398 5.70148 8.37551 5.99996 8.00732 5.99996H8.00065C7.63246 5.99996 7.33398 5.70148 7.33398 5.33329Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_768_9942">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'Info';

export const Info16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
