/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M14.1671 7.64162C14.0109 7.48641 13.7997 7.39929 13.5796 7.39929C13.3594 7.39929 13.1482 7.48641 12.9921 7.64162L10.0004 10.5916L7.0504 7.64162C6.89427 7.48641 6.68306 7.39929 6.4629 7.39929C6.24275 7.39929 6.03154 7.48641 5.8754 7.64162C5.7973 7.71909 5.7353 7.81126 5.693 7.91281C5.65069 8.01435 5.62891 8.12328 5.62891 8.23329C5.62891 8.3433 5.65069 8.45222 5.693 8.55377C5.7353 8.65532 5.7973 8.74748 5.8754 8.82495L9.40874 12.3583C9.48621 12.4364 9.57837 12.4984 9.67992 12.5407C9.78147 12.583 9.89039 12.6048 10.0004 12.6048C10.1104 12.6048 10.2193 12.583 10.3209 12.5407C10.4224 12.4984 10.5146 12.4364 10.5921 12.3583L14.1671 8.82495C14.2452 8.74748 14.3072 8.65532 14.3495 8.55377C14.3918 8.45222 14.4136 8.3433 14.4136 8.23329C14.4136 8.12328 14.3918 8.01435 14.3495 7.91281C14.3072 7.81126 14.2452 7.71909 14.1671 7.64162Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'AngleDown';

export const AngleDown20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
