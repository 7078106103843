/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_768_9954)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 1.5C11.7761 1.5 12 1.72386 12 2V5C12 5.27614 11.7761 5.5 11.5 5.5H8.5C8.22386 5.5 8 5.27614 8 5C8 4.72386 8.22386 4.5 8.5 4.5H11V2C11 1.72386 11.2239 1.5 11.5 1.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 6.72386 0.223858 6.5 0.5 6.5H3.5C3.77614 6.5 4 6.72386 4 7C4 7.27614 3.77614 7.5 3.5 7.5H1V10C1 10.2761 0.776142 10.5 0.5 10.5C0.223858 10.5 0 10.2761 0 10V7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.10867 2.15874C6.45958 1.97099 5.77352 1.95073 5.11449 2.09984C4.45546 2.24895 3.84494 2.56258 3.3399 3.01147C2.83486 3.46035 2.45177 4.02985 2.22636 4.66684C2.13424 4.92716 1.84853 5.06352 1.5882 4.9714C1.32788 4.87928 1.19152 4.59356 1.28364 4.33324C1.5654 3.53701 2.04427 2.82513 2.67557 2.26403C3.30687 1.70293 4.07001 1.31089 4.8938 1.12449C5.71759 0.938101 6.57517 0.963429 7.38653 1.19811C8.1953 1.43205 8.93187 1.86642 9.52795 2.46088L11.8424 4.63566C12.0436 4.82476 12.0535 5.14119 11.8644 5.34243C11.6753 5.54367 11.3589 5.55351 11.1576 5.36441L8.83761 3.18441C8.83381 3.18084 8.83005 3.1772 8.82636 3.1735C8.34869 2.6956 7.75775 2.34648 7.10867 2.15874ZM0.135625 6.65765C0.324721 6.45641 0.64115 6.44657 0.84239 6.63566L3.16239 8.81566C3.1662 8.81924 3.16995 8.82288 3.17364 8.82657C3.65131 9.30448 4.24225 9.65359 4.89134 9.84134C5.54042 10.0291 6.22648 10.0494 6.88552 9.90024C7.54455 9.75112 8.15507 9.43749 8.6601 8.98861C9.16514 8.53973 9.54824 7.97022 9.77364 7.33324C9.86576 7.07292 10.1515 6.93656 10.4118 7.02868C10.6721 7.1208 10.8085 7.40651 10.7164 7.66684C10.4346 8.46307 9.95573 9.17494 9.32443 9.73605C8.69314 10.2971 7.92999 10.6892 7.1062 10.8756C6.28241 11.062 5.42483 11.0366 4.61348 10.802C3.8047 10.568 3.06814 10.1337 2.47205 9.53919L0.157613 7.36441C-0.0436264 7.17532 -0.0534708 6.85889 0.135625 6.65765Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_768_9954">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconComponent.displayName = 'RefreshCw';

export const RefreshCw12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
