/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2 2.89543 2.89543 2 4 2H16C17.1046 2 18 2.89543 18 4V16C18 17.1046 17.1046 18 16 18H4C2.89543 18 2 17.1046 2 16V4ZM15.9342 8.32633L9.46918 14.7913L7.85293 13.1751L7.85304 13.1749L5.42878 10.7507L7.04503 9.13445L9.46929 11.5587L14.3179 6.71008L15.9342 8.32633Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2 2.89543 2.89543 2 4 2H16C17.1046 2 18 2.89543 18 4V16C18 17.1046 17.1046 18 16 18H4C2.89543 18 2 17.1046 2 16V4ZM15.126 7.51821C15.5723 7.96452 15.5723 8.68814 15.126 9.13445L10.2773 13.9832C9.83098 14.4295 9.10737 14.4295 8.66105 13.9832L7.85299 13.1751C7.85296 13.1751 7.85296 13.175 7.85299 13.175C7.85302 13.175 7.85302 13.1749 7.85299 13.1749L6.23691 11.5588C5.79059 11.1125 5.79059 10.3889 6.23691 9.94257C6.68322 9.49626 7.40684 9.49626 7.85315 9.94257L8.25716 10.3466C8.9266 11.016 10.012 11.016 10.6814 10.3466L13.5098 7.51821C13.9561 7.07189 14.6797 7.07189 15.126 7.51821Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'CheckboxChecked';

export const CheckboxChecked20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
