/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6C3.5 5.44772 3.94772 5 4.5 5C5.05228 5 5.5 5.44772 5.5 6C5.5 6.55228 5.05228 7 4.5 7C3.94772 7 3.5 6.55228 3.5 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 6C6.5 5.44772 6.94772 5 7.5 5C8.05228 5 8.5 5.44772 8.5 6C8.5 6.55228 8.05228 7 7.5 7C6.94772 7 6.5 6.55228 6.5 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 2.5C3.5 1.94772 3.94772 1.5 4.5 1.5C5.05228 1.5 5.5 1.94772 5.5 2.5C5.5 3.05228 5.05228 3.5 4.5 3.5C3.94772 3.5 3.5 3.05228 3.5 2.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 2.5C6.5 1.94772 6.94772 1.5 7.5 1.5C8.05228 1.5 8.5 1.94772 8.5 2.5C8.5 3.05228 8.05228 3.5 7.5 3.5C6.94772 3.5 6.5 3.05228 6.5 2.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 9.5C3.5 8.94772 3.94772 8.5 4.5 8.5C5.05228 8.5 5.5 8.94772 5.5 9.5C5.5 10.0523 5.05228 10.5 4.5 10.5C3.94772 10.5 3.5 10.0523 3.5 9.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 9.5C6.5 8.94772 6.94772 8.5 7.5 8.5C8.05228 8.5 8.5 8.94772 8.5 9.5C8.5 10.0523 8.05228 10.5 7.5 10.5C6.94772 10.5 6.5 10.0523 6.5 9.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'DragIndicator';

export const DragIndicator12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
