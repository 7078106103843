/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 9C3.5 8.72386 3.72386 8.5 4 8.5H10.5C10.7761 8.5 11 8.72386 11 9C11 9.27614 10.7761 9.5 10.5 9.5H4C3.72386 9.5 3.5 9.27614 3.5 9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 9C1 8.72386 1.22386 8.5 1.5 8.5H1.505C1.78114 8.5 2.005 8.72386 2.005 9C2.005 9.27614 1.78114 9.5 1.505 9.5H1.5C1.22386 9.5 1 9.27614 1 9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6C3.5 5.72386 3.72386 5.5 4 5.5H10.5C10.7761 5.5 11 5.72386 11 6C11 6.27614 10.7761 6.5 10.5 6.5H4C3.72386 6.5 3.5 6.27614 3.5 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6C1 5.72386 1.22386 5.5 1.5 5.5H1.505C1.78114 5.5 2.005 5.72386 2.005 6C2.005 6.27614 1.78114 6.5 1.505 6.5H1.5C1.22386 6.5 1 6.27614 1 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3C3.5 2.72386 3.72386 2.5 4 2.5H10.5C10.7761 2.5 11 2.72386 11 3C11 3.27614 10.7761 3.5 10.5 3.5H4C3.72386 3.5 3.5 3.27614 3.5 3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3C1 2.72386 1.22386 2.5 1.5 2.5H1.505C1.78114 2.5 2.005 2.72386 2.005 3C2.005 3.27614 1.78114 3.5 1.505 3.5H1.5C1.22386 3.5 1 3.27614 1 3Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'List';

export const List12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
