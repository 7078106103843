/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2C1.86739 2 1.74021 2.05268 1.64645 2.14645C1.55268 2.24021 1.5 2.36739 1.5 2.5V9.5C1.5 9.63261 1.55268 9.75978 1.64645 9.85355C1.74021 9.94732 1.86739 10 2 10H10C10.1326 10 10.2598 9.94732 10.3536 9.85355C10.4473 9.75978 10.5 9.63261 10.5 9.5V4C10.5 3.86739 10.4473 3.74021 10.3536 3.64645C10.2598 3.55268 10.1326 3.5 10 3.5H5.5C5.33282 3.5 5.17671 3.41645 5.08397 3.27735L4.23241 2H2ZM0.93934 1.43934C1.22064 1.15804 1.60218 1 2 1H4.5C4.66718 1 4.82329 1.08355 4.91603 1.22265L5.76759 2.5H10C10.3978 2.5 10.7794 2.65804 11.0607 2.93934C11.342 3.22064 11.5 3.60217 11.5 4V9.5C11.5 9.89783 11.342 10.2794 11.0607 10.5607C10.7794 10.842 10.3978 11 10 11H2C1.60217 11 1.22064 10.842 0.93934 10.5607C0.658035 10.2794 0.5 9.89783 0.5 9.5V2.5C0.5 2.10218 0.658035 1.72064 0.93934 1.43934Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5C6.27614 5 6.5 5.22386 6.5 5.5V8.5C6.5 8.77614 6.27614 9 6 9C5.72386 9 5.5 8.77614 5.5 8.5V5.5C5.5 5.22386 5.72386 5 6 5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7C4 6.72386 4.22386 6.5 4.5 6.5H7.5C7.77614 6.5 8 6.72386 8 7C8 7.27614 7.77614 7.5 7.5 7.5H4.5C4.22386 7.5 4 7.27614 4 7Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'FolderPlus';

export const FolderPlus12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
