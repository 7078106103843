/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M11.2908 12L14.8308 8.46001C15.017 8.27265 15.1216 8.0192 15.1216 7.75501C15.1216 7.49082 15.017 7.23737 14.8308 7.05001C14.7378 6.95628 14.6272 6.88189 14.5054 6.83112C14.3835 6.78035 14.2528 6.75421 14.1208 6.75421C13.9888 6.75421 13.8581 6.78035 13.7362 6.83112C13.6144 6.88189 13.5038 6.95628 13.4108 7.05001L9.1708 11.29C9.07707 11.383 9.00268 11.4936 8.95191 11.6154C8.90114 11.7373 8.875 11.868 8.875 12C8.875 12.132 8.90114 12.2627 8.95191 12.3846C9.00268 12.5064 9.07707 12.617 9.1708 12.71L13.4108 17C13.5042 17.0927 13.6151 17.166 13.7369 17.2158C13.8587 17.2655 13.9892 17.2908 14.1208 17.29C14.2524 17.2908 14.3829 17.2655 14.5047 17.2158C14.6265 17.166 14.7374 17.0927 14.8308 17C15.017 16.8126 15.1216 16.5592 15.1216 16.295C15.1216 16.0308 15.017 15.7774 14.8308 15.59L11.2908 12Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'AngleLeft';

export const AngleLeft24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
