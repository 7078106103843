/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99922 2.33569C8.44105 2.33569 8.79922 2.69387 8.79922 3.13569V12.469C8.79922 12.9109 8.44105 13.269 7.99922 13.269C7.55739 13.269 7.19922 12.9109 7.19922 12.469V3.13569C7.19922 2.69387 7.55739 2.33569 7.99922 2.33569Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.86128 7.52864C3.12163 7.26829 3.54374 7.26829 3.80409 7.52864L7.99935 11.7239L12.1946 7.52864C12.455 7.26829 12.8771 7.26829 13.1374 7.52864C13.3978 7.78899 13.3978 8.2111 13.1374 8.47145L8.47075 13.1381C8.2104 13.3985 7.78829 13.3985 7.52794 13.1381L2.86128 8.47145C2.60093 8.2111 2.60093 7.78899 2.86128 7.52864Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'ArrowDown';

export const ArrowDown16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
