/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

const IconComponent = (props: ISVGProps) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M5 6.5H1.5C1.36739 6.5 1.24021 6.55268 1.14645 6.64645C1.05268 6.74021 1 6.86739 1 7V10.5C1 10.6326 1.05268 10.7598 1.14645 10.8536C1.24021 10.9473 1.36739 11 1.5 11H5C5.13261 11 5.25979 10.9473 5.35355 10.8536C5.44732 10.7598 5.5 10.6326 5.5 10.5V7C5.5 6.86739 5.44732 6.74021 5.35355 6.64645C5.25979 6.55268 5.13261 6.5 5 6.5ZM4.5 10H2V7.5H4.5V10ZM10.5 1H7C6.86739 1 6.74021 1.05268 6.64645 1.14645C6.55268 1.24021 6.5 1.36739 6.5 1.5V5C6.5 5.13261 6.55268 5.25979 6.64645 5.35355C6.74021 5.44732 6.86739 5.5 7 5.5H10.5C10.6326 5.5 10.7598 5.44732 10.8536 5.35355C10.9473 5.25979 11 5.13261 11 5V1.5C11 1.36739 10.9473 1.24021 10.8536 1.14645C10.7598 1.05268 10.6326 1 10.5 1ZM10 4.5H7.5V2H10V4.5ZM10.5 6.5H7C6.86739 6.5 6.74021 6.55268 6.64645 6.64645C6.55268 6.74021 6.5 6.86739 6.5 7V10.5C6.5 10.6326 6.55268 10.7598 6.64645 10.8536C6.74021 10.9473 6.86739 11 7 11H10.5C10.6326 11 10.7598 10.9473 10.8536 10.8536C10.9473 10.7598 11 10.6326 11 10.5V7C11 6.86739 10.9473 6.74021 10.8536 6.64645C10.7598 6.55268 10.6326 6.5 10.5 6.5ZM10 10H7.5V7.5H10V10ZM5 1H1.5C1.36739 1 1.24021 1.05268 1.14645 1.14645C1.05268 1.24021 1 1.36739 1 1.5V5C1 5.13261 1.05268 5.25979 1.14645 5.35355C1.24021 5.44732 1.36739 5.5 1.5 5.5H5C5.13261 5.5 5.25979 5.44732 5.35355 5.35355C5.44732 5.25979 5.5 5.13261 5.5 5V1.5C5.5 1.36739 5.44732 1.24021 5.35355 1.14645C5.25979 1.05268 5.13261 1 5 1ZM4.5 4.5H2V2H4.5V4.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconComponent.displayName = 'Apps';

export const Apps12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
